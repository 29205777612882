<template>
  <div>
    <v-dialog
      v-model="dialog"
      persistent
      max-width="500"
    >
      <v-card :loading="loading">
        <v-card-title class="text-h5">
          Activate CPP Registration
        </v-card-title>
        <v-card-text class="pt-4">
          <v-text-field
            v-model="referenceNo"
            label="Reference Number"
            dense
            class="black--text mb-4"
            type="text"
            maxLength="20"
            :class="formErrorFields.transactionNumberErrorMessage.length > 0 ? 'mb-3' : ''"
            :error-messages="formErrorFields.transactionNumberErrorMessage"
            @keydown="formError().remove('transactionNumber', formErrorFields)"
          >
          </v-text-field>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
           <v-btn
            color="success"
            text
            @click="submit"
          >
            Yes
          </v-btn>
          <v-btn
            color="primary"
            text
            @click="close"
          >
            No
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    
    <snackbar
      v-if="responseMessage.length > 0"
      :show="responseMessage.length > 0"
      :text="responseMessage"
      :color="responseMessageStatus"
      @close="responseMessage=''"
    />
  </div>
</template>

<script>
import { ref, watch } from '@vue/composition-api'
import { mdiPackage } from '@mdi/js'
import crud from '@/composables/crud'
import snackbar from '@/components/SnackBar.vue'
import formError from '@/composables/formErrorHandler/formError'

export default {
  components: {
    snackbar,
  },

  props: {
    showDialog: Boolean,
    data: null,
    errorMessage: [],
  },

  setup(props, { emit }) {
    const icon = { entry: mdiPackage }
    const dialog = ref(props.showDialog)
    const referenceNo = ref('')
    const loading = ref(false)
    const { store, responseMessageStatus, responseMessage } = crud()
    const formErrorFields = ref({
      transactionNumberErrorMessage: '',
      transactionNumberErrorMessage: '',
    })

    watch(() => props.showDialog, val => {
      dialog.value = val
    })

    const close = () => {
      dialog.value = false
      emit('closeDialog')
    }

    const submit = async () => {
      await store(`${process.env.VUE_APP_URI}/api/stockist/members/activate-cpp`, loading, { 
        memberId: props.data.id,
        transactionNumber: referenceNo.value,
      }, formErrorFields)

      if (responseMessageStatus.value === 'success') {
        emit('updateTable')
        setTimeout(function() {
          emit('closeDialog')
        }, 2000)
      }
    }

    return {
      item: props.data,
      dialog,
      icon,
      referenceNo,
      submit,
      close,
      responseMessage,
      responseMessageStatus,
      loading,
      formErrorFields,
      formError,
    }
  },
}
</script>
